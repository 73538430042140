import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { SurveyCategory } from '../models/survey-category.model';

export const ADD_SURVEY_CATEGORIES      = '[SURVEY_CATEGORIES] Add';
export const CLEAR_SURVEY_CATEGORIES    = '[SURVEY_CATEGORIES] Clear';

export class AddSurveyCategoriesAction implements Action {
  readonly type = ADD_SURVEY_CATEGORIES;

  constructor(
    public payload: SurveyCategory[],
  ) {}
}

export class ClearSurveyCategoriesAction implements Action {
  readonly type = CLEAR_SURVEY_CATEGORIES;

  constructor() {}
}

export type Actions = AddSurveyCategoriesAction | ClearSurveyCategoriesAction;
