import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-surveys-dialog',
  templateUrl: 'delete-surveys-dialog.component.html',
})

export class DeleteSurveysDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public ngOnInit() { }
}
