import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../app.state';

import * as CompanyActions from '../../../actions/company.action';

import { ArrayService } from '../../../../shared/services/array.service';
import { SnackbarService } from '../../../../shared/services/snackbar.service';
import { CompanyService } from '../../../../shared/services/http/company.service';

import { DeleteCompaniesDialogComponent } from '../delete-companies-dialog.component';

import { Company } from '../../../models/company.model';

@Component({
  selector: 'app-delete-companies-form',
  templateUrl: 'delete-companies-form.component.html',
})

export class DeleteCompaniesFormComponent implements OnInit {
  @Input() companies: Company[];
  public deleteCompaniesForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<DeleteCompaniesDialogComponent>,
    private _formBuilder: FormBuilder,
    private _arrayService: ArrayService,
    private _snackbarService: SnackbarService,
    private _companyService: CompanyService,
  ) {
    this.createForm();
  }

  public ngOnInit() {}

  public deleteCompaniesOnSubmit({ value, valid }): void {
    this.isLoading = true;

    if (!value.confirmed) {
      this.isLoading = false;
      // this._snackbarService.error('One or more fields are incorrect');
      return;
    }

    this._companyService.deleteCompaniesSU(
      this._arrayService.pluck(this.companies, 'id').toString()
    ).subscribe(
      (res: any) => {
        this._store.dispatch(new CompanyActions.RemoveCompaniesAction(this.companies));

        this._snackbarService.success('Company got removed!');

        this._matDialogRef.close(true);
      },
      (err) => {
        // Error
      },
      () => {
        // Done
      }
    );
  }

  private createForm(): void {
    this.deleteCompaniesForm = this._formBuilder.group({
      confirmed: [false, Validators.required],
    });
  }
}
