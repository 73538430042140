import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor() { }

  public get(name: string): string {
    name += '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return null;
  }

  public getAll() {
    const pairs = document.cookie.split(';');
    const cookies = {};

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');

      cookies[(pair[0] + '').trim()] = unescape(pair[1]);
    }

    return cookies;
  }

  public store(name: string, value: string, days: number, path: string = '/'): void {
    const d = new Date();

    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));

    const expires = 'expires=' + d.toUTCString();

    document.cookie = name + '=' + value + ';' + expires + ';path=' + path;
  }

  public delete(name): void {
    document.cookie = name + '=; Max-Age=0';
  }

  public deleteAll(): void {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  }
}
