import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isLoggedIn: boolean = false;
  public userRole: string = '';

  constructor(
    private _router: Router,
    private _cookieService: CookieService,
  ) { }

  public logout() {
    this.isLoggedIn = false;
    this.userRole = '';
    this._cookieService.deleteAll();
    this._router.navigate(['/login']);
  }
}
