import { Action } from '@ngrx/store';

import * as CompanyActions from '../actions/company.action';

import { Company } from '../models/company.model';

export function companyReducer(
  state: Company[] = [],
  action: CompanyActions.Actions
) {
  switch (action.type) {
    case CompanyActions.ADD_COMPANIES:
      return state.concat(action.payload);
    case CompanyActions.CLEAR_COMPANIES:
      return state = [];
    case CompanyActions.REMOVE_COMPANIES:
      return state.filter(s => !action.payload.find(a => a.id === s.id));
    default:
      return state;
  }
}
