import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Statistic } from '../models/statistic.model';

export const ADD_STATISTICS      = '[STATISTICS] Add';
export const CLEAR_STATISTICS    = '[STATISTICS] Clear';

export class AddStatisticsAction implements Action {
  readonly type = ADD_STATISTICS;

  constructor(
    public payload: Statistic[],
  ) {}
}

export class ClearStatisticsAction implements Action {
  readonly type = CLEAR_STATISTICS;

  constructor() {}
}

export type Actions = AddStatisticsAction | ClearStatisticsAction;
