import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {

  constructor(
    private _api: ApiService,
  ) { }

  public getSurvey(id: string) {
    return this._api.request('GET', '/v1/surveys/' + id);
  }

  public getSurveys() {
    return this._api.request('GET', '/v1/au/surveys');
  }

  public postSurveysAU(values) {
    return this._api.request('POST', '/v1/au/surveys', values);
  }

  public getSurveyWithAccessible(values) {
    return this._api.request('POST', '/v1/surveys/accesses', values);
  }

  public deleteSurveys(ids) {
    return this._api.request('DELETE', '/v1/au/surveys/' + ids);
  }

  public sendSurveys(values, ids: string) {
    return this._api.request('POST', '/v1/au/surveys/' + ids + '/send', values);
  }
}
