import { Action } from '@ngrx/store';

import * as SurveyActions from '../actions/survey.action';

import { Survey } from '../models/survey.model';

export function surveyReducer(
  state: Survey[] = [],
  action: SurveyActions.Actions
) {
  switch (action.type) {
    case SurveyActions.ADD_SURVEYS:
      return state.concat(action.payload);
    case SurveyActions.REMOVE_SURVEYS:
      return state.filter(s => !action.payload.find(a => a.id === s.id));
    case SurveyActions.CLEAR_SURVEYS:
      return state = [];
    default:
      return state;
  }
}
