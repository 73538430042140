import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { User } from '../models/user.model';

export const ADD_USERS      = '[USERS] Add';
export const UPDATE_USERS   = '[USERS] Update';
export const REMOVE_USERS   = '[USERS] Remove';
export const CLEAR_USERS    = '[USERS] Clear';

export class AddUsersAction implements Action {
  readonly type = ADD_USERS;

  constructor(
    public payload: User[],
  ) {}
}

export class UpdateUsersAction implements Action {
  readonly type = UPDATE_USERS;

  constructor(
    public payload: User[],
  ) {}
}

export class RemoveUsersAction implements Action {
  readonly type = REMOVE_USERS;

  constructor(
    public payload: User[],
  ) {}
}

export class ClearUsersAction implements Action {
  readonly type = CLEAR_USERS;

  constructor() {}
}

export type Actions = AddUsersAction | UpdateUsersAction | RemoveUsersAction | ClearUsersAction;
