import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { CookieService } from '../../../shared/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private _cookieService: CookieService,
  ) {}

  public request(method: string, path: string, payload: object = {}) {
    const uri: string = path;

    const options: any = {
      body: JSON.stringify(payload),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this._cookieService.get('id_token')
      }),
    };

    return this.http.request(method, environment.API_URL + uri, options);
  }

}
