import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSortModule,
    MatRadioModule,
} from '@angular/material';

@NgModule({
  exports: [
    CdkTableModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatTableModule,
    MatListModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatSortModule,
    MatRadioModule,
  ],
  imports: [
    CdkTableModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatTableModule,
    MatListModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatSortModule,
    MatRadioModule,
  ],
})
export class MaterialModule {}
