import { NgModule } from '@angular/core';

import {
  ChartModule,
} from 'primeng/chart';

@NgModule({
  exports: [
    ChartModule,
  ],
  imports: [
    ChartModule,
  ],
})
export class PrimengModule {}
