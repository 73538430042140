import { Action } from '@ngrx/store';

import * as StatisticActions from '../actions/statistic.action';

import { Statistic } from '../models/statistic.model';

export function statisticReducer(
  state: Statistic[] = [],
  action: StatisticActions.Actions
) {
  switch (action.type) {
    case StatisticActions.ADD_STATISTICS:
      return state.concat(action.payload);
    case StatisticActions.CLEAR_STATISTICS:
      return state = [];
    default:
      return state;
  }
}
