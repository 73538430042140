import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private duration = 5000;

  constructor(
    private _matSnackBar: MatSnackBar,
  ) { }

  public default(value: string): void {
    this._matSnackBar.open(value, undefined, {
      duration: this.duration,
    });
  }

  public success(value: string): void {
    this._matSnackBar.open(value, undefined, {
      duration: this.duration,
    });
  }

  public warning(value: string): void {
    this._matSnackBar.open(value, undefined, {
      duration: this.duration,
    });
  }

  public error(value: string): void {
    this._matSnackBar.open(value, undefined, {
      duration: this.duration,
    });
  }
}
