import { Action } from '@ngrx/store';

import * as UserActions from '../actions/user.action';

import { User } from '../models/user.model';

export function userReducer(
  state: User[] = [],
  action: UserActions.Actions
) {
  switch (action.type) {
    case UserActions.ADD_USERS:
      return state.concat(action.payload);
    case UserActions.UPDATE_USERS:
      return state.map((s) => {
        const find = action.payload.find(a => a.id === s.id);
        return (find) ? s = find : s;
      });
    case UserActions.REMOVE_USERS:
      return state.filter(s => !action.payload.find(a => a.id === s.id));
    case UserActions.CLEAR_USERS:
      return state = [];
    default:
      return state;
  }
}
