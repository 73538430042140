import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    loadChildren: './login/login.module#LoginModule',
    path: 'login',
  },
  {
    loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule',
    path: 'forgot-password',
  },
  {
    loadChildren: './about-us/about-us.module#AboutUsModule',
    path: 'about-us',
  },
  {
    loadChildren: './terms-of-service/terms-of-service.module#TermsOfServiceModule',
    path: 'terms-of-service',
  },
  {
    loadChildren: './integrity-policy/integrity-policy.module#IntegrityPolicyModule',
    path: 'integrity-policy',
  },
  {
    loadChildren: './security/security.module#SecurityModule',
    path: 'security',
  },
  {
    loadChildren: './cookies/cookies.module#CookiesModule',
    path: 'cookies',
  },
  {
    loadChildren: './survey/survey.module#SurveyModule',
    path: 'survey',
  },
  {
    loadChildren: './su/su.module#SuModule',
    path: 'su',
  },
  {
    loadChildren: './au/au.module#AuModule',
    path: 'au',
  },
  {
    loadChildren: './not-found/not-found.module#NotFoundModule',
    path: '404',
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

export const AppRoutingModule = RouterModule.forRoot(ROUTES);
