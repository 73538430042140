import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private _api: ApiService,
  ) { }

  public getUsers() {
    return this._api.request('GET', '/v1/au/users');
  }

  public postUsers(values) {
    return this._api.request('POST', '/v1/au/users', values);
  }

  public deleteUsers(ids) {
    return this._api.request('DELETE', '/v1/au/users/' + ids);
  }

  public anonymizeUsers(ids) {
    return this._api.request('GET', '/v1/au/users/' + ids + '/anonymize');
  }

  public changePassword(values) {
    return this._api.request('POST', '/v1/au/users/me/changepassword', values);
  }
}
