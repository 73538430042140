import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';

import { InfoNewVersionDialogComponent } from '../dialogs/info-new-version-dialog/info-new-version-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {

  constructor(
    private _matDialog: MatDialog,
    private _swUpdate: SwUpdate,
  ) {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe(() => {
        this._matDialog.open(InfoNewVersionDialogComponent, {
          width: '800px',
          data: {},
        });
      });
    } else {
      console.log('Service Worker: Not enabled');
    }
  }
}
