import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../app.state';

import * as SurveyActions from '../../../actions/survey.action';

import { ArrayService } from '../../../services/array.service';
import { SurveyService } from '../../../services/http/survey.service';
import { SnackbarService } from '../../../services/snackbar.service';

import { DeleteSurveysDialogComponent } from '../delete-surveys-dialog.component';

import { Survey } from '../../../models/survey.model';

@Component({
  selector: 'app-delete-surveys-form',
  templateUrl: 'delete-surveys-form.component.html',
})

export class DeleteSurveysFormComponent implements OnInit {
  @Input() surveys: Survey[];
  public deleteSurveysForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    public location: Location,
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<DeleteSurveysDialogComponent>,
    private _formBuilder: FormBuilder,
    private _arrayService: ArrayService,
    private _snackbarService: SnackbarService,
    private _surveyService: SurveyService,
  ) {
    this.createForm();
  }

  public ngOnInit() {}

  public deleteSurveysOnSubmit({ value, valid }): void {
    this.isLoading = true;

    if (!value.confirmed) {
      this.isLoading = false;
      this._snackbarService.error('One or more fields are incorrect');
      return;
    }

    this._surveyService.deleteSurveys(
      this._arrayService.pluck(this.surveys, 'id').toString()
    ).subscribe(
      (res: any) => {
        this._store.dispatch(new SurveyActions.RemoveSurveysAction(this.surveys));

        this._snackbarService.success('Surveys got deleted!');

        this._matDialogRef.close(true);
      },
      (err) => {
        // Error
      },
      () => {
        // Done
      }
    );
  }

  private createForm(): void {
    this.deleteSurveysForm = this._formBuilder.group({
      confirmed: [false, Validators.required],
    });
  }
}
