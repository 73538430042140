import { Action } from '@ngrx/store';

import * as QuestionActions from '../actions/question.action';

import { Question } from '../models/question.model';

export function questionReducer(
  state: Question[] = [],
  action: QuestionActions.Actions
) {
  switch (action.type) {
    case QuestionActions.ADD_QUESTIONS:
      return state.concat(action.payload);
    case QuestionActions.CLEAR_QUESTIONS:
      return state = [];
    default:
      return state;
  }
}
