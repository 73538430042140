import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../app.state';

import * as UserActions from '../../../actions/user.action';

import { ArrayService } from '../../../../shared/services/array.service';
import { SnackbarService } from '../../../../shared/services/snackbar.service';
import { UserService } from '../../../../shared/services/http/user.service';

import { AnonymizeUsersDialogComponent } from '../anonymize-users-dialog.component';

import { User } from '../../../models/user.model';

@Component({
  selector: 'app-anonymize-users-form',
  templateUrl: 'anonymize-users-form.component.html',
})

export class AnonymizeUsersFormComponent implements OnInit {
  @Input() users: User[];
  public anonymizeUsersForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<AnonymizeUsersDialogComponent>,
    private _formBuilder: FormBuilder,
    private _arrayService: ArrayService,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
  ) {
    this.createForm();
  }

  public ngOnInit() {}

  public anonymizeUsersOnSubmit({ value, valid }): void {
    this.isLoading = true;

    if (!value.confirmed) {
      this.isLoading = false;
      // this._snackbarService.error('One or more fields are incorrect');
      return;
    }

    this._userService.anonymizeUsers(
      this._arrayService.pluck(this.users, 'id').toString()
    ).subscribe(
      (res: any) => {
        this._store.dispatch(new UserActions.RemoveUsersAction(res));

        this._snackbarService.success('Users got anonymized!');

        this._matDialogRef.close(true);
      },
      (err) => {
        // Error
      },
      () => {
        // Done
      }
    );
  }

  private createForm(): void {
    this.anonymizeUsersForm = this._formBuilder.group({
      confirmed: [false, Validators.required],
    });
  }
}
