import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from './app.state';

import * as SurveyActions from './shared/actions/survey.action';
import * as SurveyCategoryActions from './shared/actions/survey-category.action';
import * as UserActions from './shared/actions/user.action';

import { AppService } from './shared/services/app.service';
import { CookieService } from './shared/services/cookie.service';
import { SwUpdateService } from './shared/services/sw-update.service';

import { Jwt } from './shared/models/jwt.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public menus: any[] = [
    [
      {
        name: 'Login',
        path: '/login',
        icon: 'person',
        disabled: false,
        hidden: false,
        requireLogin: false,
        userRoles: '',
        au: false,
        ru: false,
      },
      {
        name: 'Forgot password',
        path: '/forgot-password',
        icon: 'lock_open',
        disabled: true,
        hidden: true,
        requireLogin: false,
        userRoles: '',
        au: false,
        ru: false,
      },
      {
        name: 'Companies',
        path: '/su/companies',
        icon: 'group',
        disabled: false,
        hidden: false,
        requireLogin: true,
        userRoles: 'su',
        au: true,
        ru: false,
      },
      {
        name: 'Users',
        path: '/au/users',
        icon: 'group',
        disabled: false,
        hidden: false,
        requireLogin: true,
        userRoles: 'au',
        au: true,
        ru: false,
      },
      {
        name: 'Surveys',
        path: '/au/surveys',
        icon: 'question_answer',
        disabled: false,
        hidden: false,
        requireLogin: true,
        userRoles: 'au',
        au: true,
        ru: false,
      },
      {
        name: 'Statistics',
        path: '/au/statistics',
        icon: 'bar_chart',
        disabled: false,
        hidden: false,
        requireLogin: true,
        userRoles: 'au',
        au: true,
        ru: false,
      },
      {
        name: 'Settings',
        path: '/au/settings',
        icon: 'settings',
        disabled: false,
        hidden: false,
        requireLogin: true,
        userRoles: 'au',
        au: true,
        ru: false,
      },
    ],
    [
      {
        name: 'About us',
        path: '/about-us',
        icon: 'info',
        disabled: false,
        hidden: false,
        requireLogin: false,
        userRoles: ',su,au',
        au: true,
        ru: true,
      },
      {
        name: 'Terms of service',
        path: '/terms-of-service',
        icon: 'info',
        disabled: false,
        hidden: false,
        requireLogin: false,
        userRoles: ',su,au',
        au: true,
        ru: true,
      },
      {
        name: 'Integrity policy',
        path: '/integrity-policy',
        icon: 'info',
        disabled: false,
        hidden: false,
        requireLogin: false,
        userRoles: ',su,au',
        au: true,
        ru: true,
      },
      {
        name: 'Security',
        path: '/security',
        icon: 'info',
        disabled: true,
        hidden: true,
        requireLogin: false,
        userRoles: ',su,au',
        au: true,
        ru: true,
      },
      {
        name: 'Cookies',
        path: '/cookies',
        icon: 'info',
        disabled: false,
        hidden: false,
        requireLogin: false,
        userRoles: ',su,au',
        au: true,
        ru: true,
      },
    ],
  ];
  private token: string;

  constructor(
    public appService: AppService,
    private _store: Store<AppState>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    private _swUpdateService: SwUpdateService,
  ) {}

  public ngOnInit() {
    this.token = this._cookieService.get('id_token');

    if (this.token) {
      this.appService.userRole = new Jwt(this.token).role;
    }

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
        document.getElementsByClassName('mat-sidenav-content')[0].scrollTop = 0;
      }
    });

    if (this.token) {
      this.appService.userRole = new Jwt(this.token).role;

      switch (this.appService.userRole) {
        case 'au':
          this.appService.isLoggedIn = true;
          break;
        case 'ru':
          this.appService.isLoggedIn = true;
          break;
        case 'su':
          this.appService.isLoggedIn = true;
          break;
        default:
          this.appService.isLoggedIn = false;
          this._cookieService.delete('id_token');
          break;
      }

      if (window.location.pathname === '/') {
        this._router.navigate(['/' + this.appService.userRole]);
      }
    } else {
      this.appService.isLoggedIn = false;
      this._cookieService.delete('id_token');
    }
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public logout() {
    this.appService.isLoggedIn = false;
    this.appService.userRole = '';
    this._cookieService.deleteAll();
    this._store.dispatch(new SurveyActions.ClearSurveysAction());
    this._store.dispatch(new SurveyCategoryActions.ClearSurveyCategoriesAction());
    this._store.dispatch(new UserActions.ClearUsersAction());
    this._router.navigate(['/']);
  }
}
