import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Survey } from '../models/survey.model';

export const ADD_SURVEYS      = '[SURVEYS] Add';
export const REMOVE_SURVEYS   = '[SURVEYS] Remove';
export const CLEAR_SURVEYS    = '[SURVEYS] Clear';

export class AddSurveysAction implements Action {
  readonly type = ADD_SURVEYS;

  constructor(
    public payload: Survey[],
  ) {}
}

export class RemoveSurveysAction implements Action {
  readonly type = REMOVE_SURVEYS;

  constructor(
    public payload: Survey[],
  ) {}
}

export class ClearSurveysAction implements Action {
  readonly type = CLEAR_SURVEYS;

  constructor() {}
}

export type Actions = AddSurveysAction | RemoveSurveysAction | ClearSurveysAction;
