import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() isBackable: boolean = false;

  constructor(
    private _location: Location,
  ) {}

  public ngOnInit() {}

  private goBack() {
    this._location.back();
  }
}
