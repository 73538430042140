import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  constructor(
    private _api: ApiService,
  ) { }

  public getCompaniesSU() {
    return this._api.request('GET', '/v1/su/companies');
  }

  public postCompaniesSU(values) {
    return this._api.request('POST', '/v1/su/companies', values);
  }

  public deleteCompaniesSU(ids) {
    return this._api.request('DELETE', '/v1/su/companies/' + ids);
  }
}
