import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Question } from '../models/question.model';

export const ADD_QUESTIONS      = '[QUESTIONS] Add';
export const CLEAR_QUESTIONS    = '[QUESTIONS] Clear';

export class AddQuestionsAction implements Action {
  readonly type = ADD_QUESTIONS;

  constructor(
    public payload: Question[],
  ) {}
}

export class ClearQuestionsAction implements Action {
  readonly type = CLEAR_QUESTIONS;

  constructor() {}
}

export type Actions = AddQuestionsAction | ClearQuestionsAction;
