import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-anonymize-users-dialog',
  templateUrl: 'anonymize-users-dialog.component.html',
})

export class AnonymizeUsersDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public ngOnInit() {}
}
