import { Action } from '@ngrx/store';

import * as SurveyCategoryActions from '../actions/survey-category.action';

import { SurveyCategory } from '../models/survey-category.model';

export function surveyCategoryReducer(
  state: SurveyCategory[] = [],
  action: SurveyCategoryActions.Actions
) {
  switch (action.type) {
    case SurveyCategoryActions.ADD_SURVEY_CATEGORIES:
      return state.concat(action.payload);
    case SurveyCategoryActions.CLEAR_SURVEY_CATEGORIES:
      return state = [];
    default:
      return state;
  }
}
