import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

import { Company } from '../models/company.model';

export const ADD_COMPANIES      = '[COMPANIES] Add';
export const CLEAR_COMPANIES    = '[COMPANIES] Clear';
export const REMOVE_COMPANIES   = '[COMPANIES] Remove';

export class AddCompaniesAction implements Action {
  readonly type = ADD_COMPANIES;

  constructor(
    public payload: Company[],
  ) {}
}

export class ClearCompaniesAction implements Action {
  readonly type = CLEAR_COMPANIES;

  constructor() {}
}

export class RemoveCompaniesAction implements Action {
  readonly type = REMOVE_COMPANIES;

  constructor(
    public payload: Company[],
  ) {}
}

export type Actions = AddCompaniesAction | ClearCompaniesAction | RemoveCompaniesAction;
