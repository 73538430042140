import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { environment } from '../environments/environment';

import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { questionReducer } from './shared/reducers/question.reducer';
import { surveyReducer } from './shared/reducers/survey.reducer';
import { surveyCategoryReducer } from './shared/reducers/survey-category.reducer';
import { userReducer } from './shared/reducers/user.reducer';
import { statisticReducer } from './shared/reducers/statistic.reducer';
import { companyReducer } from './shared/reducers/company.reducer';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    StoreModule.forRoot({
      questions: questionReducer,
      surveys: surveyReducer,
      surveyCategories: surveyCategoryReducer,
      statistics: statisticReducer,
      users: userReducer,
      companies: companyReducer,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
