import { Location } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as SurveyActions from '../../../actions/survey.action';

import { ArrayService } from '../../../services/array.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SurveyService } from '../../../services/http/survey.service';

import { SendSurveysDialogComponent } from '../send-surveys-dialog.component';

import { AppState } from '../../../../app.state';
import { Survey } from '../../../models/survey.model';
import { User } from '../../../models/user.model';

@Component({
  selector: 'app-send-surveys-form',
  templateUrl: 'send-surveys-form.component.html',
})

export class SendSurveysFormComponent implements OnInit {
  @Input() users: User[];
  public surveys: Survey[];
  // public selectedSurveys: Survey[];
  public sendSurveysForm: FormGroup;
  public isLoading: boolean = false;
  public isFetchingSurveys: boolean = false;

  constructor(
    public location: Location,
    private _router: Router,
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<SendSurveysDialogComponent>,
    private _formBuilder: FormBuilder,
    private _arrayService: ArrayService,
    private _snackbarService: SnackbarService,
    private _surveyService: SurveyService,
  ) {
    this.createForm();
  }

  public ngOnInit() {
    let fetchedOne = false;

    this._store.select('surveys').subscribe(
      (s) => {
        if (!s.length && !fetchedOne) {
          this._surveyService.getSurveys().subscribe(
            (res: any) => {
              fetchedOne = true;
              this._store.dispatch(new SurveyActions.AddSurveysAction(res));
            },
            (err) => {
              // Error
            },
            () => {
              this.isFetchingSurveys = false;
            }
          );
        } else {
          this.isFetchingSurveys = false;
          this.surveys = s;
        }
      }
    );
  }

  public sendSurveysOnSubmit({ value, valid }): void {
    this.isLoading = true;

    if (!valid) {
      this.isLoading = false;
      this._snackbarService.error('One or more fields are incorrect');
      return;
    }

    this._surveyService.sendSurveys({
      emails: this._arrayService.pluck(this.users, 'username').toString(),
    }, this._arrayService.pluck(this.sendSurveysForm.value.surveys, 'id').toString()).subscribe(
      (res) => {
        this._snackbarService.success('Surveys got sent!');

        this._matDialogRef.close(true);
      },
      (err) => {
        // Error
      },
      () => {
        // Done
      },
    );

  }

  public goToSurveys(): void {
    this._router.navigate(['/au/surveys/view']);
  }

  private createForm(): void {
    this.sendSurveysForm = this._formBuilder.group({
      surveys: [[], Validators.required],
    });
  }
}
