import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../app.state';

import * as UserActions from '../../../actions/user.action';

import { ArrayService } from '../../../services/array.service';
import { UserService } from '../../../services/http/user.service';
import { SnackbarService } from '../../../services/snackbar.service';

import { DeleteUsersDialogComponent } from '../delete-users-dialog.component';

import { User } from '../../../models/user.model';

@Component({
  selector: 'app-delete-users-form',
  templateUrl: 'delete-users-form.component.html',
})

export class DeleteUsersFormComponent implements OnInit {
  @Input() users: User[];
  public deleteUsersForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    public location: Location,
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<DeleteUsersDialogComponent>,
    private _formBuilder: FormBuilder,
    private _arrayService: ArrayService,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
  ) {
    this.createForm();
  }

  public ngOnInit() {}

  public deleteUsersOnSubmit({ value, valid }): void {
    this.isLoading = true;

    if (!value.confirmed) {
      this.isLoading = false;
      this._snackbarService.error('One or more fields are incorrect');
      return;
    }

    this._userService.deleteUsers(
      this._arrayService.pluck(this.users, 'id').toString()
    ).subscribe(
      (res: any) => {
        this._store.dispatch(new UserActions.RemoveUsersAction(this.users));

        this._snackbarService.success('Users got deleted!');

        this._matDialogRef.close(true);
      },
      (err) => {
        // Error
      },
      () => {
        // Done
      }
    );
  }

  private createForm(): void {
    this.deleteUsersForm = this._formBuilder.group({
      confirmed: [false, Validators.required],
    });
  }
}
