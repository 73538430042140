import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-info-new-version-dialog',
  templateUrl: 'info-new-version-dialog.component.html',
})

export class InfoNewVersionDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public ngOnInit() {}

  public reloadPage() {
    window.location.reload();
  }
}
