import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './modules/material.module';
import { PrimengModule } from './modules/primeng.module';

import { DeleteSurveysDialogComponent } from './dialogs/delete-surveys-dialog/delete-surveys-dialog.component';
import { DeleteSurveysFormComponent } from './dialogs/delete-surveys-dialog/delete-surveys-form/delete-surveys-form.component';
import { DeleteUsersDialogComponent } from './dialogs/delete-users-dialog/delete-users-dialog.component';
import { DeleteUsersFormComponent } from './dialogs/delete-users-dialog/delete-users-form/delete-users-form.component';
import { SendSurveysDialogComponent } from './dialogs/send-surveys-dialog/send-surveys-dialog.component';
import { SendSurveysFormComponent } from './dialogs/send-surveys-dialog/send-surveys-form/send-surveys-form.component';
import { AnonymizeUsersDialogComponent } from './dialogs/anonymize-users-dialog/anonymize-users-dialog.component';
import { AnonymizeUsersFormComponent } from './dialogs/anonymize-users-dialog/anonymize-users-form/anonymize-users-form.component';
import { DeleteCompaniesDialogComponent } from './dialogs/delete-companies-dialog/delete-companies-dialog.component';
import { DeleteCompaniesFormComponent } from './dialogs/delete-companies-dialog/delete-companies-form/delete-companies-form.component';
import { InfoTutorialDialogComponent } from './dialogs/info-tutorial-dialog/info-tutorial-dialog.component';
import { InfoNewVersionDialogComponent } from './dialogs/info-new-version-dialog/info-new-version-dialog.component';

import { ToolbarComponent } from '../shared/components/toolbar/toolbar.component';

@NgModule({
  declarations: [
    DeleteSurveysDialogComponent,
    DeleteSurveysFormComponent,
    DeleteUsersDialogComponent,
    DeleteUsersFormComponent,
    ToolbarComponent,
    SendSurveysDialogComponent,
    SendSurveysFormComponent,
    AnonymizeUsersDialogComponent,
    AnonymizeUsersFormComponent,
    DeleteCompaniesDialogComponent,
    DeleteCompaniesFormComponent,
    InfoTutorialDialogComponent,
    InfoNewVersionDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PrimengModule,
    ReactiveFormsModule,
  ],
  entryComponents: [
    DeleteSurveysDialogComponent,
    DeleteUsersDialogComponent,
    SendSurveysDialogComponent,
    AnonymizeUsersDialogComponent,
    DeleteCompaniesDialogComponent,
    InfoTutorialDialogComponent,
    InfoNewVersionDialogComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    PrimengModule,
    ReactiveFormsModule,
    ToolbarComponent,
  ],
})
export class SharedModule {}
